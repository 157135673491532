import React, { useEffect } from "react";
import styled from "styled-components";

import InputField from "../InputField";
import { FORM_VALIDATION } from "modules/auth/constants";

function MFAForm(props) {
  const { errors, setErrors, control } = props;

  useEffect(() => {
    setErrors({});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <p>
          A multi-factor authentication code has been sent to you via email or
          authenticator app (if enabled). Please enter the code below to
          continue.
        </p>
      </div>
      <StyledContainer>
        <InputField
          name="code"
          label={<b>Authentication Code</b>}
          control={control}
          size="lg"
          type="number"
          validation={{ ...FORM_VALIDATION.code }}
          isVertical={true}
          errors={errors}
        />
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export default MFAForm;
