import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import "modules/lm/style/custom.scss";

import "modules/lm/style";
import Routes from "modules/auth/routes/Routes";
import history from "modules/lm/history";
import { Provider as UserContextProvider } from "modules/auth/context/UserContext";
import theme from "./theme";
import muiTheme from "./muiTheme";

function App() {
  return (
    <MUIThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ToastContainer
            position="bottom-right"
            pauseOnFocusLoss={false}
            pauseOnHover
            toastStyle={{ backgroundColor: theme.validation }}
          />
          <UserContextProvider>
            <div className="h-100">
              <Routes />
            </div>
          </UserContextProvider>
        </Router>
      </ThemeProvider>
    </MUIThemeProvider>
  );
}

export default App;
