import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";

import Loading from "modules/auth/components/Loading";
import ProfileForm from "modules/auth/forms/ProfileForm";
import { Context as UserContext } from "modules/auth/context/UserContext";
import { Provider as FormContextProvider } from "modules/auth/context/FormContext";
import ViewActions from "../ViewActions";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";

function ProfileViewComponent() {
  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // ref hooks for programmatic form submit
  //
  const submitRef = useRef(null);

  //
  // constants
  //
  let passwordAction = (
    <Link to="/profile/password">
      <Button
        className="ms-2"
        variant="contained"
        color="primary"
        size="medium"
      >
        Change Password
      </Button>
    </Link>
  );

  let emailAction = (
    <Link to="/profile/email">
      <Button
        className="ms-2"
        variant="contained"
        color="primary"
        size="medium"
      >
        Change Email
      </Button>
    </Link>
  );

  let backAction = (
    <Link to="/">
      <Button variant="outlined" color="primary" size="medium">
        Return to Dashboard
      </Button>
    </Link>
  );

  if (user?.staff_user) {
    passwordAction = null;
    emailAction = null;
  }

  const customActions = (
    <>
      {backAction}
      <Link to="/profile/totp">
        <Button
          className="ms-2"
          variant="contained"
          color="primary"
          size="medium"
        >
          Authenticator App
        </Button>
      </Link>
      {passwordAction}
      {emailAction}
    </>
  );

  const header = (
    <>
      <StyledH2>
        {user?.first_name} {user?.last_name}'s Profile
      </StyledH2>
      <ViewActions
        submitRef={submitRef}
        customViewActions={customActions}
        showEdit={!user?.staff_user}
      />
    </>
  );

  return user ? (
    <FormViewWrapper
      viewHeader={header}
      form={<ProfileForm submitRef={submitRef} defaultValues={user} />}
    />
  ) : (
    <Loading />
  );
}

const ProfileView = () => (
  <FormContextProvider>
    <ProfileViewComponent />
  </FormContextProvider>
);

const StyledH2 = styled.h2`
  color: ${props => props.theme.charcoal};
  font-weight: 700;
`;

export default ProfileView;
