import React, { useState, useEffect } from "react";
import qs from "qs";
import styled from "styled-components";

import LoginForm from "modules/auth/forms/LoginForm";
import loginTranslations from "./loginTranslations";
import { Context as FormContext } from "modules/auth/context/FormContext";
import { LOGIN_VIEW_MESSAGES } from "modules/auth/constants";
import favicon from "modules/auth/assets/Favicon.svg";
import MediaAisle from "modules/auth/assets/MediaAisle.svg";
import Waves from "modules/auth/assets/waves.svg";
import { Button, Card, Alert, Box } from "@mui/material";
import Banner from "modules/auth/components/Banner";

function LoginView({ location: { search } }) {
  const [message, setMessage] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const language = search.includes("lg=fr") ? "FR" : "EN";
  useEffect(() => {
    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    delete queryParams.lg;
    const message = Object.keys(queryParams)[0];

    if (message) {
      setMessage(message);
    }
  }, [search]);

  return (
    <StyledDiv>
      <Banner language={language} />
      <FlexContainer>
        <PurpleWaveContainer px={10}>
          <StyledTitle className="text-white display-1">
            {loginTranslations.login.textTitle[language]}
            <img className="ms-4" style={{ width: 50 }} src={favicon} alt="" />
          </StyledTitle>
          <div className="d-flex align-items-end">
            <LoginContainer>
              <img
                src={MediaAisle}
                className="pb-5"
                style={{ maxWidth: "200px" }}
                alt=""
              />
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                height={"100%"}
              >
                {apiErrorMessage ? (
                  <Alert className="mb-3" severity="error">
                    {apiErrorMessage}
                  </Alert>
                ) : (
                  message && (
                    <Alert
                      className="mb-3"
                      severity={message === "logout" ? "success" : "error"}
                    >
                      {LOGIN_VIEW_MESSAGES[message]}
                    </Alert>
                  )
                )}
                <FormContext.Provider value={{ state: { edit: true } }}>
                  <LoginForm
                    setApiErrorMessage={setApiErrorMessage}
                    userLabel={loginTranslations.login.email[language]}
                    passwordLabel={loginTranslations.login.password[language]}
                    buttonLabel={loginTranslations.login.next[language]}
                  />
                </FormContext.Provider>
              </Box>
            </LoginContainer>
          </div>
        </PurpleWaveContainer>
      </FlexContainer>

      <LightPurpleContainer
        px={10}
        className="d-flex justify-content-start align-items-start"
      >
        <Box py={8} style={{ position: "relative", width: "70%" }}>
          <div className="d-flex flex-column">
            <StyledHeader className="text-left fw-bold">
              {loginTranslations.block1.title[language]}
            </StyledHeader>
            <span className="text-left" style={{ fontSize: "20px" }}>
              {loginTranslations.block1.sub[language]}
            </span>
            <Button
              onClick={() =>
                (window.location.href =
                  loginTranslations.block1.btnLink[language])
              }
              variant="outlined"
              color="primary"
              size="large"
              style={{ width: "fit-content" }}
            >
              {loginTranslations.block1.btn[language]}
            </Button>
          </div>
        </Box>
      </LightPurpleContainer>

      <BottomContainer
        p={8}
        className="d-flex flex-column justify-content-between align-items-center"
      >
        <div className="w-75">
          <div style={{ position: "relative" }}>
            <h1 className="text-center fw-bold" style={{ fontSize: "32px" }}>
              {loginTranslations.block2.title[language]}
            </h1>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() =>
                (window.location.href =
                  loginTranslations.block2.talkBtnLink[language])
              }
              variant="contained"
              color="primary"
              size="large"
              style={{ border: "2px solid white" }}
            >
              {loginTranslations.block2.talkBtn[language]}
            </Button>
          </div>
        </div>
      </BottomContainer>
    </StyledDiv>
  );
}

const StyledHeader = styled.h1`
  color: ${props => props.theme.purple_6};
  font-size: 40px;
`;

const StyledDiv = styled(Box)`
  background-color: ${props => props.theme.purple_1};
`;

const FlexContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 450px;
  @media (max-width: 1100px) {
    flex-direction: row;
    height: 640px;
  }
`;

const PurpleWaveContainer = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(${Waves});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: start;
  }
`;

const LoginContainer = styled(Card)`
  position: absolute;
  width: 604px;
  min-height: 350px;
  padding: 60px 32px 60px 32px;
  z-index: 2;
  bottom: -50px;
  right: 80px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    position: relative;
    bottom: 0px;
    right: 0px;
  }
`;

const BottomContainer = styled(Box)`
  background-color: ${props => props.theme.purple_6};
  color: ${props => props.theme.font_light};
`;

const LightPurpleContainer = styled(Box)`
  background-color: ${props => props.theme.purple_1};
  color: ${props => props.theme.new_charcoal};
`;

const StyledTitle = styled.p`
  z-index: 2;
  width: 40%;
  font-size: 60px;
  font-weight: 700;
`;

export default LoginView;
