import React from "react";

function PasswordRules() {
  return (
    <div className="password-rules-container">
      <p>
        <strong>Password Rules</strong>
      </p>
      <p>Your password must have:</p>
      <ul>
        <li>A length between 6 and 30 characters.</li>
        <li>At least one uppercase letter, lowercase letter, or digit.</li>
        <li>Have no more than 4 occurrences of the same character.</li>
      </ul>
    </div>
  );
}

export default PasswordRules;
