import styled from "styled-components";

const StyledSpan = styled.span`
  color: ${props => props.theme.purple_2};
`;

const StyledU = styled.u`
  color: ${props => props.theme.new_charcoal};
`;

const loginTranslations = {
  banner: {
    text: {
      EN: "We've refreshed our look! Same great features, new style.",
      FR:
        "Notre plateforme s’est refait une beauté! Les mêmes fonctionnalités exceptionnelles, maintenant avec un nouveau style."
    }
  },
  login: {
    textTitle: {
      EN: (
        <span>
          Where brands and customers <StyledSpan>click</StyledSpan>
        </span>
      ),
      FR: "Là où marques et clients se connectent"
    },
    title: {
      EN: (
        <span>
          MediaAisle<sup>TM</sup> Login
        </span>
      ),
      FR: (
        <span>
          Connexion MediaAisle<sup>MC</sup>
        </span>
      )
    },
    email: {
      EN: "Email Address",
      FR: "Adresse courriel"
    },
    password: {
      EN: "Password",
      FR: "Le mot de passe"
    },
    next: {
      EN: "Next",
      FR: "Suivant"
    }
  },
  block1: {
    title: {
      EN: <span>Your omnichannel solution for media buying.</span>,
      FR: <span>Une nouvelle plateforme d’achat novatrice</span>
    },
    sub: {
      EN: (
        <span>
          <p className="pb-4">
            At{" "}
            <a
              href="https://www.loblawadvance.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                <StyledU>Loblaw Advance</StyledU>
              </b>
            </a>
            ™ we are on a mission to reinvent media buying in Canada.
          </p>
          <p className="pb-4">
            Our MediaAisle™ media buying platform drives real, measurable sales
            impacts for your brand, for sponsored products on our e-commerce
            sites and for programmatic display and online video across the
            digital ecosystem.
          </p>
          <p className="pb-4">
            Optimize your ad buying based on almost real-time retail sales
            results with our built-in closed-loop measurement.
          </p>
        </span>
      ),
      FR: (
        <span>
          <p className="pb-4">
            Nous avons pour mission de réinventer l’achat de médias au Canada.
          </p>
          <p className="pb-4">
            Notre plateforme d’achat de médias MediaAisle<sup>MC</sup> entraîne
            des répercussions réelles et mesurables sur les ventes de votre
            marque, pour les produits commandités sur nos sites de commerce
            électronique et pour l’affichage programmatique et la vidéo en ligne
            dans l’ensemble de l’écosystème numérique.
          </p>
          <p className="pb-4">
            Optimisez vos achats publicitaires en fonction des résultats de
            ventes au détail, presque en temps réel, grâce à notre système de
            mesure intégré en circuit fermé.
          </p>
        </span>
      )
    },
    btn: {
      EN: "Discover More",
      FR: "En découvrir plus"
    },
    btnLink: {
      EN: "https://www.loblawadvance.ca/discover-mediaaisle",
      FR: "https://www.loblawadvance.ca/decouvrez-mediaaisle"
    }
  },
  block2: {
    title: {
      EN: (
        <span>
          Ready to unlock the power of precision media buying with MediaAisle
          <sup>TM</sup>?
        </span>
      ),
      FR: (
        <span>
          Êtes-vous prêt à exploiter toute la puissance des achats d’espace de
          précision avec MediaAisle
          <sup>MC</sup>?
        </span>
      )
    },
    talkBtn: {
      EN: "Let's Talk",
      FR: "Parlons-en"
    },
    talkBtnLink: {
      EN: "https://www.loblawadvance.ca/contact-us",
      FR: "https://www.loblawadvance.ca/nous-contacter"
    },
    visitBtn: {
      EN: "Visit Loblawadvance.ca",
      FR: "Visitez Loblawacceleration.ca"
    },
    visitBtnLink: {
      EN: "https://www.loblawadvance.ca/",
      FR: "https://www.loblawacceleration.ca/"
    }
  }
};

export default loginTranslations;
