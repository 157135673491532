import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import { StyledPageOverlay } from "modules/auth/components-styled";

function PageOverlay({
  messageTitle = "Uh oh, something went wrong",
  messageBody = "Please try refreshing the page, or logging out and logging back in.",
  content = null,
  showActions = true
}) {
  const history = useHistory();
  //
  // functions
  //
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <StyledPageOverlay>
      <h2>{messageTitle}</h2>
      {messageBody && <p>{messageBody}</p>}
      {content}
      {showActions && (
        <div className="d-flex mt-3">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRefresh}
          >
            Refresh
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="ms-3"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      )}
    </StyledPageOverlay>
  );
}

export default PageOverlay;
