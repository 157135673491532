import { createTheme } from "@mui/material/styles";
import theme from "theme";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.primary,
      contrastText: theme.white
    }
  },
  typography: {
    fontFamily: '"HelveticaNeue", sans-serif' // Apply the font globally
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none", // disable uppercase text default
          "&:hover": {
            boxShadow: "0px 4px 4px 0px #00000033"
          },
          "&:active": {
            boxShadow: "none"
          }
        },
        containedPrimary: {
          backgroundColor: theme.primary,
          color: theme.white,
          boxShadow: "none",
          "&:active": {
            backgroundColor: theme.purple_6
          },
          "&.MuiButton-sizeSmall": {
            padding: "8px 12px"
          },
          "&.MuiButton-sizeMedium": {
            padding: "10px 16px"
          },
          "&.MuiButton-sizeLarge": {
            padding: "12px 28px"
          }
        },
        outlinedPrimary: {
          color: theme.primary,
          backgroundColor: theme.white,
          border: `2px solid ${theme.primary}`,
          "&:active": {
            color: theme.purple_6,
            border: `2px solid ${theme.purple_6}`
          },
          "&.MuiButton-sizeSmall": {
            padding: "7px 11px" // -1 to accommodate border
          },
          "&.MuiButton-sizeMedium": {
            padding: "9px 15px" // -1 to accommodate border
          },
          "&.MuiButton-sizeLarge": {
            padding: "11px 27px" // -1 to accommodate border
          }
        },
        sizeMedium: {
          fontSize: "16px"
        },
        sizeLarge: {
          fontSize: "16px"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "none",
          background: theme.white,
          border: `1px solid ${theme.cool_gray}`
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          alignItems: "center",
          color: theme.charcoal
        },
        standardWarning: {
          backgroundColor: theme.chartreuse_light
        },
        standardError: {
          backgroundColor: theme.error_light_2,
          border: `2px solid ${theme.error}`,
          borderRadius: "8px"
        },
        standardSuccess: {
          backgroundColor: theme.validation_light_2,
          border: `2px solid ${theme.validation}`,
          borderRadius: "8px"
        }
      }
    }
  }
});

export default muiTheme;
