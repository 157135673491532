import React, { useEffect, useState } from "react";
import qs from "qs";
import styled from "styled-components";

import MFAForm from "modules/auth/forms/MFAForm";
import { Context as FormContext } from "modules/auth/context/FormContext";
import { MFA_VIEW_MESSAGES } from "modules/auth/constants";
import { Alert } from "@mui/material";

function MFAViewComponent({ location: { search } }) {
  //
  // state hooks
  //
  const [param, setParam] = useState("");

  useEffect(() => {
    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true,
      parameterLimit: 1
    });

    const param = Object.keys(queryParams)[0];

    if (param) {
      setParam(param);
    }
  }, [search]);

  return (
    <MFAFormWrapper>
      <MFAContainer>
        <h3>Enter MFA Code</h3>
        {param ? (
          <Alert
            className="mb-3"
            severity={param === "logout" ? "success" : "error"}
          >
            {MFA_VIEW_MESSAGES[param]}
          </Alert>
        ) : null}
        <MFAForm />
      </MFAContainer>
    </MFAFormWrapper>
  );
}

const MFAFormWrapper = styled.div`
  border-radius: 10px;
  border: ${props => `1px solid ${props.theme.primary}`};
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  margin-top: 200px;
`;

const MFAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  min-width: 700px;

  h3 {
    margin-bottom: 40px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  a {
    color: ${props => props.theme.blue_jay};
    text-align: right;
    text-decoration: none;
    display: inline-block;
    font-size: 1.4rem;
  }

  min-height: 300px;
  min-width: 375px;

  p {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const MFAView = props => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <MFAViewComponent {...props} />
  </FormContext.Provider>
);

export default MFAView;
