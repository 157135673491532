import React, { useState, useEffect } from "react";
import axios from "axios";
import objectPath from "object-path";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";

import InputField from "../InputField";
import LoginFormActions from "./LoginFormActions";
import { FORM_VALIDATION } from "modules/auth/constants";
import MFAForm from "./MFAForm";
import IdentificationList from "./IdentificationList";
import googleLogo from "modules/auth/assets/google_signin.png";

function LoginForm(props) {
  const { userLabel, passwordLabel, setApiErrorMessage } = props;
  const [action, setAction] = useState({});
  const [usernameIsEvaluated, setUsernameIsEvaluated] = useState(false);
  const [errors, setErrors] = useState({});
  const [identificationOrg, setIdentificationOrg] = useState(null);
  const [requestCodeResend, setRequestCodeResend] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cookies] = useCookies(["XSRF-TOKEN"]);
  const { setValue, handleSubmit, control, watch } = useForm({
    defaultValues: {
      username: ""
    }
  });

  const username = watch("username");
  const defaultError = "Something went wrong on our end, please try again";

  useEffect(() => {
    if (inputValue !== username) {
      setValue("username", inputValue);
    }
    //eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    setApiErrorMessage(errors?.message);
    // eslint-disable-next-line
  }, [errors]);

  const onNextClick = async e => {
    e.preventDefault();
    const value = {
      action: "USERNAME",
      username: inputValue
    };
    const result = await challengeAuth(value);
    setAction(result);
    setUsernameIsEvaluated(true);
  };

  const onBackClick = e => {
    e.preventDefault();
    setValue("password", "");
    setAction({});
    setApiErrorMessage(null);
    setUsernameIsEvaluated(false);
  };

  let googleWindow = null;

  async function onSsoCallback(event) {
    // See oauth-callback.html for message format
    let message = event.data;

    // If Google SSO succeed
    if (message.code) {
      const body = {
        username: inputValue,
        action: "SSO",
        state: message["state"],
        code: message["code"]
      };

      await challengeAuth(body);
    }
  }

  function loginWithGoogle() {
    window.removeEventListener("message", onSsoCallback);
    if (googleWindow === null || googleWindow.closed) {
      googleWindow = window.open(
        action.url,
        "Google Sign In",
        "toolbar=no,menubar=no,scrollbars=yes,status=yes,width=600,height=700,top=100,left=100"
      );
    }

    window.addEventListener("message", onSsoCallback, false);
  }

  async function formSubmit(value) {
    const config = {
      headers: {
        cookies: cookies
      }
    };

    if (requestCodeResend && action.action === "MFA_CODE") {
      config.params = {
        email: "true"
      };
    }

    const data =
      action.action === "IDENTIFICATION"
        ? {
            action: action.action,
            username: username,
            org_id: identificationOrg
          }
        : action.action === "MFA_CODE"
        ? {
            action: action.action,
            username: username,
            mfa_code: value.code
          }
        : {
            action: action.action,
            username: username,
            password: value.password
          };

    await challengeAuth(data, config);
  }

  async function resendMFACode() {
    setRequestCodeResend(true);

    const config = {
      headers: {
        cookies: cookies
      }
    };

    const data = {
      action: "RESEND_CODE"
    };

    try {
      await axios.post("/challenge", data, config);
    } catch (error) {
      const errorData = objectPath.get(error, "response.data.content", {
        message: defaultError
      });
      setErrors({ message: errorData.message });
    }
  }

  // challenge endpoint manager
  async function challengeAuth(value, config = {}) {
    try {
      const response = await axios.post("/challenge", value, config);
      const result = objectPath.get(response, "data.content", {});

      if (result.error) {
        setErrors({ message: result.message || defaultError });
      }

      if (result.action === "REDIRECT") {
        window.location.href = result.url;
      } else if (result.action === "MFA_CODE") {
        setAction({
          action: "MFA_CODE"
        });
      } else if (result.action === "IDENTIFICATION") {
        setAction(result);
      }

      return result;
    } catch (error) {
      const errorData = objectPath.get(error, "response.data.content", {
        message: defaultError
      });
      setErrors(errorData);
    }
  }

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      onNextClick(event);
    }
  };

  return (
    // react typically does not use native form actions,
    // we are supposed to use a handler in the onSubmit prop.
    // This was done intentionally, to account for BE limitations
    // see LME-538 for details
    <form
      className="d-flex flex-column justify-content-between h-100"
      onSubmit={handleSubmit(formSubmit)}
    >
      <div>
        {action?.action === "MFA_CODE" ? (
          <MFAForm errors={errors} control={control} setErrors={setErrors} />
        ) : action?.action === "IDENTIFICATION" ? (
          <IdentificationList
            identifications={action.identifications}
            primaryOrg={action.primary_org}
            setIdentificationOrg={setIdentificationOrg}
            identificationOrg={identificationOrg}
          />
        ) : (
          <>
            <InputField
              name="username"
              label={userLabel || "Username"}
              control={control}
              size="lg"
              type="text"
              isVertical={true}
              errors={errors}
              onChange={handleInputChange}
              readOnly={usernameIsEvaluated}
              onKeyDown={handleKeyDown}
            />
            {usernameIsEvaluated && action?.action === "PASSWORD" ? (
              <div>
                <InputField
                  name="password"
                  autoFocus
                  label={passwordLabel || "Password"}
                  type="password"
                  control={control}
                  size="lg"
                  validation={{ ...FORM_VALIDATION.password }}
                  isVertical={true}
                  errors={errors}
                />
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <InputField
                    name="remember-me"
                    label="Stay Logged In"
                    type="checkbox"
                    control={control}
                    size="lg"
                    isVertical={false}
                    columnClassNames={{
                      label: "col-3",
                      value: "d-flex col"
                    }}
                    errors={errors}
                  />
                </div>
              </div>
            ) : action?.action === "SSO" ? (
              <div className="pt-3">
                <p className="">Please log in below with Google.</p>
                {action?.action === "SSO" ? (
                  <img
                    style={{ cursor: "pointer" }}
                    src={googleLogo}
                    alt="Google SignIn"
                    onClick={loginWithGoogle}
                  />
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="">
        <LoginFormActions
          action={action}
          setAction={setAction}
          usernameIsEvaluated={usernameIsEvaluated}
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          usernameInput={inputValue}
          username={username}
          resendCode={resendMFACode}
          setErrors={setErrors}
        />
      </div>
    </form>
  );
}

export default LoginForm;
