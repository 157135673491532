import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";

import { Context as FormContext } from "modules/auth/context/FormContext";

function ViewActions({
  submitRef,
  customEditingActions,
  customViewActions,
  submitOnly = false,
  showEdit = true,
  submitLabel = "Submit"
}) {
  //
  // useContext hooks
  //
  const {
    state: { edit },
    setEdit
  } = useContext(FormContext);

  //
  // functions
  //
  const submitForm = () => submitRef.current.click();

  const handleKeyPress = (e, cb, ...args) => {
    const key = e.key || e.which;
    if (key === "Enter" || key === 13) {
      cb(...args);
    }
  };

  return (
    <ViewActionsContainer>
      {edit ? (
        <>
          {customEditingActions}

          <Button
            className="ms-2"
            variant="contained"
            color="primary"
            size="medium"
            onClick={submitForm}
            tabIndex={0}
            onKeyPress={e => handleKeyPress(e, submitForm)}
          >
            {submitLabel}
          </Button>

          {!submitOnly && (
            <Button
              className="ms-2"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => setEdit(false)}
              onKeyPress={e => handleKeyPress(e, setEdit, false)}
              tabIndex={0}
            >
              Cancel
            </Button>
          )}
        </>
      ) : (
        <>
          {customViewActions}
          {showEdit && (
            <Button
              className="ms-2"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => setEdit(true)}
              onKeyPress={e => handleKeyPress(e, setEdit, true)}
              tabIndex={0}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </ViewActionsContainer>
  );
}

const ViewActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default ViewActions;
