import React, { useContext } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { ReactComponent as MediaAisle } from "modules/auth/assets/MediaAisle.svg";
import StyledDivWrapper from "./StyledDivWrapper";

function AppHeader() {
  const {
    state: { user }
  } = useContext(UserContext);
  const history = useHistory();

  const altText =
    "MediaAisle logo with 'Media' in purple and 'Aisle' in black bold letters.";

  const handleLogout = () => {
    history.push("/logout");
  };

  return (
    <StyledHeader>
      <StyledNavWrapper>
        <StyledBrandLogo>
          <Link to={user ? "/" : "/login"}>
            <MediaAisle aria-label={altText} />
          </Link>
        </StyledBrandLogo>
        <StyledNavItems>
          {user && (
            <li className="d-flex align-items-center">
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </li>
          )}
        </StyledNavItems>
      </StyledNavWrapper>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  min-height: 104px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.nav_top_bg};
  color: ${props => props.theme.new_charcoal};
  z-index: 2;
`;

const StyledBrandLogo = styled.div`
  width: 200px;
  svg {
    width: 100%;
  }
`;

const StyledNavItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 1.4rem;
  li {
    margin-left: 30px;
    &:hover {
      color: ${props => props.theme.hover_nav};
    }
  }

  a {
    color: ${props => props.theme.lm_black};
    text-decoration: none;
    display: inline-block;
    margin-left: 4px;
    &:hover {
      color: ${props => props.theme.hover_nav};
    }
  }
`;

const StyledNavWrapper = styled(StyledDivWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default AppHeader;
