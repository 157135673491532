import React from "react";
import { Card } from "@mui/material";

function FormViewWrapper({ viewHeader, form, viewAppend, viewPrepend }) {
  return (
    <div className="view-wrapper">
      <div className="view-header">{viewHeader}</div>
      {viewPrepend}
      <Card>
        <div className="form-container">{form}</div>
      </Card>
      {viewAppend}
    </div>
  );
}

export default FormViewWrapper;
