import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";

import { Context as UserContext } from "modules/auth/context/UserContext";
import { Context as FormContext } from "modules/auth/context/FormContext";
import Loading from "modules/auth/components/Loading";
import ViewActions from "../ViewActions";
import UpdatePasswordForm from "modules/auth/forms/UpdatePasswordForm";
import PasswordRules from "./PasswordRules";
import FormViewWrapper from "modules/auth/components/FormViewWrapper";

function UpdatePasswordViewComponent() {
  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // ref hooks
  //
  const submitRef = useRef(null);

  //
  // constants
  //
  const customActions = (
    <Link to="/profile">
      <Button variant="outlined" color="primary" size="medium">
        Return to Profile
      </Button>
    </Link>
  );

  const header = (
    <>
      <StyledH2>
        Update password for {user.first_name} {user.last_name}
      </StyledH2>
      <ViewActions
        submitOnly
        customEditingActions={customActions}
        submitRef={submitRef}
      />
    </>
  );

  return (
    <>
      {user ? (
        <FormViewWrapper
          viewHeader={header}
          form={<UpdatePasswordForm submitRef={submitRef} />}
          viewAppend={<PasswordRules />}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

const UpdatePasswordView = () => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <UpdatePasswordViewComponent />
  </FormContext.Provider>
);

const StyledH2 = styled.h2`
  color: ${props => props.theme.charcoal};
  font-weight: 700;
`;

export default UpdatePasswordView;
