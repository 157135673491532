import React, { useState, useEffect } from "react";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import wavingHand from "modules/auth/assets/wavingHand.png";
import loginTranslations from "../views/LoginView/loginTranslations";

// COMPONENT IS NOT NEEDED WHEN PAST 20 days
function Banner({ language }) {
  const [open, setOpen] = useState(() => {
    return localStorage.getItem("banner") === "false" ? false : true;
  });
  const currentDate = new Date();
  const bannerExpirationDate = new Date("2024-10-01");

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("banner", "false");
  };

  useEffect(() => {
    localStorage.setItem("banner", open ? "true" : "false");
  }, [open]);

  if (currentDate >= bannerExpirationDate) {
    return null;
  }

  return (
    <>
      {open && (
        <Alert
          icon={<img src={wavingHand} alt="" width={25} />}
          severity="warning"
          style={{ padding: "16px 32px 16px 32px" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          }
        >
          {loginTranslations.banner.text[language]}
        </Alert>
      )}
    </>
  );
}

export default Banner;
