const lm_black = "rgb(0, 0, 0)";
const lm_red = "rgb(225, 37, 27)";
const lm_pink = "rgb(252, 157, 135)";
const lm_teal = "#00A39B";
const lm_dark_teal = "#004C57";
const lm_dark_grey = "rgb(152, 152, 154)";
const lm_grey = "rgb(200, 200, 200)";
const blue_jay = "#5474AB";

// LD rebrand
// Purples
const purple_1 = "#F8EAFF"; // Lightest shade
const purple_2 = "#E8B7FF";
const purple_3 = "#D06BFF";
const purple_4 = "#AB24EA"; // Primary
const purple_5 = "#7300A8"; // Call to action
const purple_6 = "#470068"; // Darkest shade

// Secondary colors
const cool_gray = "#EBE9EA";
const silver = "#C4C4C4";
const graphite = "#757575";
const new_charcoal = "#201D1E";
const chartreuse_light = "#FBFFE6";
const white = "#ffffff";

// Accent colors
const lime = "#899E00";
const cherry = "#F60A98";
const mint = "#00A390";
const orange = "#EB7100";
const blueberry = "#2E42AA";

// Status/state colors
const error = "#850040";
const error_light = "#F3E6EC";
const error_light_2 = "#FFF8FB";
const validation = "#1A7A58";
const validation_light = "#E9F2EF";
const validation_light_2 = "#EFFFF9";

const charcoal = "#353535";
const background_gray = "#F5F5F5";

const theme = {
  primary: purple_5,
  secondary: background_gray,
  warning: "#E8A029",
  warning_light: "#FFE8C2",
  danger: error,
  danger_light: error_light,

  purple_1,
  purple_2,
  purple_3,
  purple_4,
  purple_5,
  purple_6,

  cool_gray,
  silver,
  graphite,
  new_charcoal,
  chartreuse_light,
  white,

  lime,
  cherry,
  mint,
  orange,
  blueberry,

  error,
  validation,
  validation_light,
  validation_light_2,
  error_light,
  error_light_2,

  charcoal,
  background_gray,

  // primary: lm_teal,
  dark_primary: lm_dark_teal,
  // secondary: "#f5f7fa",
  font_primary: "#656565",
  font_light: "#ffffff",
  body_bg: lm_grey,
  disabled_dark: "rgb(147, 183, 189)",
  hover_nav: lm_teal,
  tile_shadow: lm_teal,
  lm_black,
  lm_red,
  lm_pink,
  lm_dark_grey,
  nav_top_bg: "#fff",
  medium_grey_1: "#c5c5c5",
  blue_jay
};

export default theme;
