import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";

import { Context as FormContext } from "modules/auth/context/FormContext";
import ForgotPasswordForm from "modules/auth/forms/ForgotPasswordForm";

function ForgotPasswordViewComponent({ location: { state = {} } }) {
  return (
    <StyledCard>
      <h2 className="pb-2">Forgot Your Password?</h2>
      <ForgotPasswordForm defaultValues={state} />
    </StyledCard>
  );
}

const ForgotPasswordView = props => (
  <FormContext.Provider value={{ state: { edit: true } }}>
    <ForgotPasswordViewComponent {...props} />
  </FormContext.Provider>
);

const StyledCard = styled(Card)`
  width: 700px;
  padding: 25px;
  margin: auto;
  margin-top: 50px;
`;

export default ForgotPasswordView;
