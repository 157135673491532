import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import authApi from "modules/auth/api";

import Loading from "modules/auth/components/Loading";
import PageOverlay from "modules/auth/components/PageOverlay";
import { Context as UserContext } from "modules/auth/context/UserContext";
import DashboardApplications from "./DashboardApplications";
import Banner from "modules/auth/components/Banner";

function DashboardView({ location: { search } }) {
  //
  // state hooks
  //
  const [apps, setApps] = useState(null);
  const [error, setError] = useState(false);

  //
  // context hooks
  //
  const {
    state: { user }
  } = useContext(UserContext);

  //
  // useEffect hooks
  //
  const language = search.includes("lg=fr") ? "FR" : "EN";

  useEffect(() => {
    async function fetchApps() {
      try {
        const response = await authApi.get("/apps");
        const appList = response.data.content.concat([
          {
            name: "My Account",
            identifier: "profile",
            url: `profile`,
            external: false,
            description: "Access your user profile."
          }
        ]);

        const indexOfCM = appList.findIndex(obj => obj.identifier === "cm");

        // if indexOfCM === -1, the CM tile is not in the list
        // if indexOfCM === 0, the CM tile is already the first tile
        if (indexOfCM > 0) {
          const copy = appList[indexOfCM];
          appList.splice(indexOfCM, 1);
          appList.unshift(copy);
        }

        setApps(appList);
      } catch (e) {
        setError(true);
      }
    }

    fetchApps();
  }, []);

  if (error) {
    return <PageOverlay />;
  }
  return (
    <DashboardViewContainer>
      {user && apps ? (
        <div className="position-relative pb-5 h-100">
          <Banner language={language} />
          <div className="position-relative" style={{ zIndex: 2 }}>
            <DashboardApplications apps={apps} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </DashboardViewContainer>
  );
}

const DashboardViewContainer = styled.div`
  background-color: ${props => props.theme.background_gray};
`;

export default DashboardView;
